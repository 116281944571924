/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import Input from 'components/shared/FormElements/Input/Input';

const PhoneNumber = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, showGenericError } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  // Logic used to validate fields
  const fieldValidation = (name) => {
    return register({ required: `${name} is required` });
  };

  return (
    <div>
      {/* Show generic error message */}
      {showGenericError}

      <Input
        className="ds-col-1 ds-col-lg-3-4"
        name="PhoneNumber"
        label="Phone number*<br> For example, 07700900457"
        autocomplete="phone-number"
        fieldValidation={fieldValidation('Phone number')}
      />
    </div>
  );
};

export default PhoneNumber;
