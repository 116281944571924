import React, { useRef } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import IncidentTypeList from 'components/Form/Shared/IncidentType/IncidentTypeList';

const IncidentType = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  // Check it we are facing an existing user

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 2 of 3" description="About the incident" />

      {/* Show generic error message */}
      {showGenericError}

      {/* Show list */}
      <IncidentTypeList />

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default IncidentType;
