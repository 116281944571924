import React, { useRef, useContext } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import Radios from 'components/shared/FormElements/Radios/Radios';
import Input from 'components/shared/FormElements/Input/Input';
import Dropdown from 'components/shared/FormElements/Dropdown/Dropdown';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import { useFormContext } from 'react-hook-form';
// import useFormData from '../../../useFormData';

const IncidentLocation = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)
  const { register } = useFormContext(); // Get useForm methods
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext

  // Check it we are facing an existing user

  const radioButtons = [
    { text: 'Wolverhampton to Edgbaston', value: 'Wolverhampton to Edgbaston' },
    { text: 'Edgbaston to Wolverhampton)', value: 'Edgbaston to Wolverhampton' },
  ];

  // Labels used on inputs and for validation
  const travelAreaLabel = 'Tram direction of travel*';
  const detailsLabel = 'Provide as much detail as possible';

  const streetLabel = 'Nearest Station';

  const options = [
    { label: '', value: '' },
    { label: 'Edgbaston Village', value: 'Edgbaston Village' },
    { label: 'Five Ways', value: 'Five Ways' },
    { label: 'Brindleyplace', value: 'Brindleyplace' },
    { label: 'Library Centenary Square', value: 'Library Centenary Square' },
    { label: 'Town Hall', value: 'Town Hall' },
    { label: 'Grand Central', value: 'Grand Central' },
    { label: 'Corporation Street', value: 'Corporation Street' },
    { label: 'Bull Street', value: 'Bull Street' },
    { label: 'St Chads', value: 'St Chads' },
    { label: 'St Paul’s', value: 'St Paul’s' },
    { label: 'Jewellery Quarter', value: 'Jewellery Quarter' },
    { label: 'Soho Benson Road', value: 'Soho Benson Road' },
    { label: 'Winson Green Outer Circle', value: 'Winson Green Outer Circle' },
    { label: 'Handsworth Booth Street', value: 'Handsworth Booth Street' },
    { label: 'The Hawthorns', value: 'The Hawthorns' },
    { label: 'Kenrick Park', value: 'Kenrick Park' },
    { label: 'Trinity Way', value: 'Trinity Way' },
    { label: 'West Bromwich Central', value: 'West Bromwich Central' },
    { label: 'Lodge Road', value: 'Lodge Road' },
    { label: 'Dartmouth Street', value: 'Dartmouth Street' },
    { label: 'Dudley Street Guns Village', value: 'Dudley Street Guns Village' },
    { label: 'Black Lake', value: 'Black Lake' },
    { label: 'Wednesbury Great Western Street', value: 'Wednesbury Great Western Street' },
    { label: 'Wednesbury Parkway', value: 'Wednesbury Parkway' },
    { label: 'Bradley Lane', value: 'Bradley Lane' },
    { label: 'Loxdale', value: 'Loxdale' },
    { label: 'Bilston Central', value: 'Bilston Central' },
    { label: 'The Crescent', value: 'The Crescent' },
    { label: 'Priestfield', value: 'Priestfield' },
    { label: 'The Royal', value: 'The Royal' },
    { label: 'Piper’s Row', value: 'Piper’s Row' },
    { label: 'Wolverhampton Station', value: 'Wolverhampton Station' },
    { label: 'Wolverhampton St George’s', value: 'Wolverhampton St George’s' },
  ];

  const areaOptions = [
    { label: '', value: '' },
    { label: 'Platform', value: 'Platform' },
    { label: 'Car park', value: 'Car park' },
    { label: 'Cycle rack', value: 'Cycle rack' },
    { label: 'Concourse', value: 'Concourse' },
    { label: 'Bridge', value: 'Bridge' },
    { label: 'Booking office', value: 'Booking office' },
    { label: 'Ticket barrier', value: 'Ticket barrier' },
    { label: 'Waiting room / shelter', value: 'Waiting room / shelter' },
    { label: 'Stairs', value: 'Stairs' },
    { label: 'Toilet', value: 'Toilet' },
    { label: 'Lift', value: 'Lift' },
  ];

  const tramStation = formDataState.formData.TramStop ? formDataState.formData.TramStop : null;
  const tramArea = formDataState.formData.TramStopArea ? formDataState.formData.TramStopArea : null;

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 2 of 3" description="About the incident" />

      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">Where did the incident happen?</h2>
          <p>{detailsLabel}</p>
          <p>{travelAreaLabel}</p>
        </legend>

        <Radios
          name="TramDirection"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
        />

        <Dropdown
          name="TramStop"
          options={options}
          label="Nearest tram station*"
          defaultValue={tramStation}
          fieldValidation={register({
            required: `Please select nearest tram station`,
          })}
        />

        <Dropdown
          name="TramStopArea"
          options={areaOptions}
          label="What area of the stop did the incident occur?"
          defaultValue={tramArea}
          fieldValidation={register({
            required: false,
          })}
        />
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default IncidentLocation;
