import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Import components
import Icon from 'components/shared/Icon/Icon';
// Import contexts
import { useFormContext } from 'react-hook-form';
// Import styles
import s from './FileUpload.module.scss';

const FileUpload = ({ name, fieldValidation }) => {
  const { errors } = useFormContext();

  // Local state for controlling file upload
  const [isFileInputFocused, setIsFileInputFocused] = useState(false); // This is used to emulate the input focus class on the label
  const [fileName, setFileName] = useState('Upload photo');
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
  }, [selectedFile]);

  // Used to change the name of the input/label button to the users file name
  const handleChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    const file = e.target.files[0];

    // If a file exists (user hasn't clicked cancel button or something)
    if (file) {
      setFileName(file.name); // Set file name that the user has chosen (this will display in our label)
      // example uses the first image instead of multiple
      setSelectedFile(e.target.files[0]);
    }
  };

  // HandleFocus (when user joins input)
  const handleFocus = () => {
    setIsFileInputFocused(true); // Set input to focus
  };

  // Handleblur (when user leaves input), set input to unfocus
  const handleBlur = () => setIsFileInputFocused(false);

  return (
    <fieldset className="ds-fe-fieldset">
      <legend className="ds-fe-fieldset__legend">
        <p>The file must be a jpeg or png file format.</p>
      </legend>
      <div className={`ds-fe-group ${errors[name] ? 'ds-fe-group--error' : ''}`}>
        {/* If there is an error, show here */}
        {errors[name] && <span className="ds-fe-error-message">{errors[name].message}</span>}
        <label
          htmlFor="fileUpload"
          className={`ds-btn ds-btn--primary ${isFileInputFocused ? s.fileUploadLabelFocused : ''}`}
        >
          {fileName}
          <Icon className="ds-btn__icon ds-btn__icon--right" iconName="general-paperclip" />
          <input
            type="file"
            name={name}
            id="fileUpload"
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            className={s.fileUpload}
            ref={fieldValidation}
          />
        </label>
        <br />
        <br />
        <br />
        <div>{selectedFile && <img src={preview} alt="Uploaded" />}</div>
      </div>
    </fieldset>
  );
};

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  fieldValidation: PropTypes.func,
};

FileUpload.defaultProps = {
  fieldValidation: null,
};

export default FileUpload;
