import React, { useRef, useContext } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Context
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import Radios from 'components/shared/FormElements/Radios/Radios';
import Input from 'components/shared/FormElements/Input/Input';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
// import useFormData from '../../../useFormData';

const ReportToPolice = () => {
  const [, formDispatch] = useContext(FormDataContext); // Get the state of form data from FormDataContext
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  // Logic used to validate fields
  const fieldValidation = (name) => {
    return register({ required: `${name} is required` });
  };
  const [formDataState] = useContext(FormDataContext);

  // locker number input src
  const crimerefSrc = (
    <div className="ds-m-l-xl">
      <Input
        className="ds-col-1 ds-col-lg-3-4"
        name="CrimeReference"
        label="What was the crime reference number?"
        fieldValidation={fieldValidation('What was the crime reference number?')}
      />
    </div>
  );

  // Add referal input if true
  let crimeref;

  const safeguardingData = (e) => {
    formDispatch({
      type: 'UPDATE_FORM_DATA',
      payload: { ReportToPolice: e.target.value },
    });
  };

  // display the locker input
  if (formDataState.formData.ReportToPolice === 'Yes') {
    crimeref = crimerefSrc;
  } else {
    formDataState.formData.CrimeReference = null;
  }

  const radioButtons = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
  ];

  const title =
    'If the person or group refused to leave the bus station, or the problem escalated, did you report the incident to the police?';

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 3 of 3" description="Your response" />
      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{title}</h2>
        </legend>

        <Radios
          name="ReportToPolice"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
          change={safeguardingData}
        />
        {crimeref}
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default ReportToPolice;
