import React, { useRef } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import { useFormContext } from 'react-hook-form';
import Radios from 'components/shared/FormElements/Radios/Radios';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import FileUpload from '../FileUpload/FileUpload';

const UploadOffenderPhoto = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)
  const { register } = useFormContext();

  // Add InsetText with extra info when selected option is "no"
  let extraInfo;

  const radioButtons = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
  ];

  const fileValidation = (uploadedFiles) => {
    let error;

    const file = uploadedFiles[0];

    // If a file exists (user hasn't clicked cancel button or something)
    if (file) {
      const PhotoBase64Extension = file.type.split('/')[1]; // => image/png (split at '/' and grab second part 'png')

      if (
        PhotoBase64Extension !== 'png' &&
        PhotoBase64Extension !== 'jpg' &&
        PhotoBase64Extension !== 'jpeg' &&
        PhotoBase64Extension !== 'pdf'
      ) {
        error = 'The selected file must be a JPG, PNG or PDF';
      } else if (file.size > 4194304) {
        error = 'The selected file must be smaller than 4MB';
      }
    }

    return error;
  };

  // const title = 'Do you have a photo of the offender?';
  const title = 'Are you able to supply the images via email?';

  const fileUploadValidation = register({
    required: 'Select a photo',
    validate: {
      fileValid: (value) => fileValidation(value) && fileValidation(value),
    },
  });
  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 2 of 2" description="About the incident" />

      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{title}</h2>
        </legend>

        <p>
          Send your image as an attachment on email to SaferTravelASBTeam@wmca.org.uk. To help us
          associate your photo with your report, please include your forename and surname in the
          email.
        </p>
        <Radios
          name="areYouAbleToSupplyTheImageViaEmail"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
        />
        {extraInfo}
      </fieldset>
      {/* <FileUpload name="UploadPhoto" fieldValidation={fileUploadValidation} /> */}
      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default UploadOffenderPhoto;
