import React, { useRef, useContext } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Context
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import Radios from 'components/shared/FormElements/Radios/Radios';
import Textarea from 'components/shared/FormElements/Input/textarea';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
// import useFormData from '../../../useFormData';

const Safeguarding = () => {
  const [, formDispatch] = useContext(FormDataContext); // Get the state of form data from FormDataContext
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  // Logic used to validate fields
  const fieldValidation = (name) => {
    return register({ required: `${name} is required` });
  };
  const [formDataState] = useContext(FormDataContext);

  // locker number input src
  const referalSrc = (
    <div className="ds-m-l-xl">
      <Textarea
        className="ds-col-1 ds-col-lg-3-4"
        name="ReferralType"
        label="What type of referral/vulnerability? For example homelessness or alcoholism"
        fieldValidation={fieldValidation('What type of referral/vulnerability?')}
      />
    </div>
  );

  // Add referal input if true
  let referal;

  const safeguardingData = (e) => {
    formDispatch({
      type: 'UPDATE_FORM_DATA',
      payload: { SafeguardingReferrals: e.target.value },
    });
  };

  // display the locker input
  if (formDataState.formData.SafeguardingReferrals === 'Yes') {
    referal = referalSrc;
  } else {
    formDataState.formData.ReferralType = null;
  }

  const radioButtons = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
    { text: 'Not applicable', value: 'NA' },
  ];

  const title =
    'In line with the TfWM Safeguarding Policy, did you put in any safeguarding/vulnerability referrals to your line manager?';

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 3 of 3" description="Your response" />
      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{title}</h2>
        </legend>

        <Radios
          name="SafeguardingReferrals"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
          change={safeguardingData}
        />
        {referal}
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default Safeguarding;
