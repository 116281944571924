/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useContext } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import Dropdown from 'components/shared/FormElements/Dropdown/Dropdown';
import Input from 'components/shared/FormElements/Input/Input';
import { useFormContext } from 'react-hook-form';
import { FormDataContext } from 'globalState/FormDataContext';

const Name = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext
  const { showGenericError } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)
  const { register } = useFormContext(); // Get useForm methods

  const title = formDataState.formData.Title;

  const options = [
    { label: 'Choose from list', value: '' },
    { label: 'Mr', value: 'Mr' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Ms', value: 'Ms' },
    { label: 'Dr', value: 'Dr' },
    { label: 'Rev', value: 'Rev' },
  ];

  // Labels used on inputs and for validation
  const fNameLabel = 'First name*';
  const lNameLabel = 'Last name*';

  return (
    <div>
      {/* Show generic error message */}
      {showGenericError}

      <Dropdown
        name="Title"
        options={options}
        label="Title*"
        autocomplete="incident-type"
        defaultValue={title}
        fieldValidation={register({
          required: `Please select one option to proceed`,
        })}
      />

      <Input
        className="ds-col-1 ds-col-lg-3-4"
        name="Firstname"
        label={fNameLabel}
        autocomplete="given-name"
        fieldValidation={register({
          required: `Please enter your First name to proceed`,
        })}
      />
      <Input
        className="ds-col-1 ds-col-lg-3-4"
        name="LastName"
        label={lNameLabel}
        autocomplete="family-name"
        fieldValidation={register({
          required: `Please enter your Last  name to proceed`,
        })}
      />
    </div>
  );
};

export default Name;
