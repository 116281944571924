import React, { useRef, useContext } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Context
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import Radios from 'components/shared/FormElements/Radios/Radios';
import Input from 'components/shared/FormElements/Input/Input';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
// import useFormData from '../../../useFormData';

const Bodycam = () => {
  const [, formDispatch] = useContext(FormDataContext); // Get the state of form data from FormDataContext
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  // Logic used to validate fields
  const fieldValidation = (name) => {
    return register({ required: `${name} is required` });
  };
  const [formDataState] = useContext(FormDataContext);

  // Log number input src
  const logNumberSrc = (
    <div className="ds-m-l-xl">
      <Input
        className="ds-col-1 ds-col-lg-3-4"
        name="BodycamLogNumber"
        label="What was the log number?"
        fieldValidation={fieldValidation('Log number')}
      />
    </div>
  );

  // Add log input if true
  let logNumber;

  const bodycamData = (e) => {
    formDispatch({
      type: 'UPDATE_FORM_DATA',
      payload: { Bodycam: e.target.value },
    });
  };

  // display the locker input
  if (formDataState.formData.Bodycam === 'Yes') {
    logNumber = logNumberSrc;
  } else {
    formDataState.formData.BodycamLogNumber = null;
  }

  const radioButtons = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
  ];

  const title = 'Did you turn on your bodycam?';

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 3 of 3" description="Your response" />
      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{title}</h2>
        </legend>

        <Radios
          name="Bodycam"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
          change={bodycamData}
        />
        {logNumber}
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default Bodycam;
