/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import Input from 'components/shared/FormElements/Input/Input';
import InputCheckbox from '../../../shared/FormElements/Input/InputCheckbox';

const Terms = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, showGenericError } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  // Logic used to validate fields
  const fieldValidation = (name) => {
    return register({ required: `${name} is required` });
  };

  // Labels used on inputs and for validation
  const accurancyLabel = (
    <>
      Any incidents reported through the CRM or via email/online reporting will be checked by ASB
      Staff with the CCTV Control Room, so please ensure your reports are accurate. Any false
      reporting will be forwarded to management for appropriate action. &nbsp;
    </>
  );
  const courtConsentLabel = (
    <>
      Any reports received may result in a formal statement and may be used in evidence at Court.
      &nbsp;
    </>
  );

  // Logic used to validate the terms field
  const checkboxValidation = register({
    required: 'Agree to form accuracy before you continue',
    validate: {
      shouldBeTrue: (val) => val === true || 'Agree to form accuracy before you continue',
    },
  });
  // Logic used to validate the policy field
  const polictCheckboxValidation = register({
    required: 'Agree to court evidence before you continue',
    validate: {
      shouldBeTrue: (val) => val === true || 'Agree to court evidence before you continue',
    },
  });

  return (
    <div>
      {/* Show generic error message */}
      {showGenericError}

      <InputCheckbox
        name="AccurancyConsent"
        type="checkbox"
        fieldValidation={checkboxValidation}
        labelElement={accurancyLabel}
      />
      <InputCheckbox
        name="CourtConsent"
        type="checkbox"
        fieldValidation={polictCheckboxValidation}
        labelElement={courtConsentLabel}
      />
    </div>
  );
};

export default Terms;
