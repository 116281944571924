/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from 'react';
import dompurify from 'dompurify';

import Input from 'components/shared/FormElements/Input/Input';
import Dropdown from 'components/shared/FormElements/Dropdown/Dropdown';
import Button from 'components/shared/Button/Button';
import MapView from 'components/shared/MapView/MapView';
import GetMap from 'components/shared/Map/Map';

// Import contexts
import { useFormContext } from 'react-hook-form';
import { FormDataContext } from 'globalState/FormDataContext';

import s from '../../../../App.module.scss';

const { sanitize } = dompurify;

const Address = ({ register, unregister }) => {
  const [, formDispatch] = useContext(FormDataContext); // Get the state of form data from FormDataContext
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext

  const [mapView, setMapView] = useState(formDataState.formData.ViewMap);
  const [inputValues, setInputValues] = useState([]);
  const { TransportMode, postcode, ViewMap, BusStation } = formDataState.formData;
  const [station, seStation] = useState('');
  const { errors } = useFormContext();

  // Labels used on inputs and for validation
  const busNumberLabel = 'Bus number';
  const travelDirectionLabel = 'Direction of travel<br> For example, towards Birmingham';
  const travelAreaLabel = 'Town or area <br> For example, Halesowen';
  const streetLabel = 'Street name';

  const options = [
    { label: 'Choose from list', value: '' },
    { label: 'Bearwood', value: 'Bearwood' },
    { label: 'Bilston', value: 'Bilston' },
    { label: 'Cradley Heath', value: 'Cradley Heath' },
    { label: 'Dudley', value: 'Dudley' },
    { label: 'Halesowen', value: 'Halesowen' },
    { label: 'Pool Meadow, Coventry', value: 'Pool Meadow, Coventry' },
    { label: 'Stourbridge', value: 'Stourbridge' },
    { label: 'Walsall', value: 'Walsall' },
    { label: 'Wednesbury', value: 'Wednesbury' },
    { label: 'West Bromwich', value: 'West Bromwich' },
    { label: 'Wolverhampton', value: 'Wolverhampton' },
  ];

  const checkBoxesChangeHandler = () => {
    const findInputs = [...document.querySelectorAll('.mapInput')].map((input) => input.value);
    setInputValues(findInputs);
  };

  const registerRef = (idx) => {
    if (inputValues.every((input) => !input)) {
      return true;
    }
    if (inputValues[idx]) {
      return true;
    }

    return false;
  };
  useEffect(() => {
    if (
      TransportMode &&
      TransportMode.toLowerCase() === 'bus' &&
      postcode !== null &&
      postcode !== undefined
    ) {
      setMapView(true);
    } else {
      setMapView(false);
    }

    if (BusStation) {
      seStation(BusStation);
    }
  }, []);

  const getCoords = (value) => {
    if (value) {
      const coords = value.split('query=')[1].split(',');
      return coords;
    }
    return []; // Or handle the case where value is undefined in your preferred way
  };

  const fieldValidation = register({
    required: 'Enter a Date of the incident',
  });

  return (
    <div className="wmnds-fe-group">
      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">Where did the incident happen?</h2>
          <p>Provide as much detail as possible</p>
        </legend>
      </fieldset>
      <>
        <div className="ds-col-auto" style={{ marginBottom: 30 }}>
          <Button
            text={mapView ? 'List view' : 'Map view'}
            btnClass="ds-btn--secondary"
            iconRight={mapView ? 'general-list' : 'general-location-pin'}
            onClick={() => setMapView(!mapView)}
          />
        </div>
        <input name="ViewMap" ref={fieldValidation} value={mapView} type="hidden" />
        {mapView && (
          <div>
            <ul>
              <li>
                Find the location by searching for a place or postcode, or using your location.
              </li>
              <li>
                Click on the Add point button. Click on the map where you want the point to appear.
              </li>
            </ul>

            {/* <div id="searchWidget" /> */}

            <div className="ds-fe-group">
              <label className="ds-fe-label" htmlFor="mapBusNumber">
                {/* Bus number */}
                <Input
                  className="ds-col-1 ds-col-lg-3-4"
                  name="BusServiceNumber"
                  label="Bus number*"
                  // autocomplete="given-name"
                  fieldValidation={register({
                    required: `Please enter bus number`,
                  })}
                />
              </label>

              <label className="ds-fe-label" htmlFor="postcode">
                Place or postcode
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div id="searchWidget" />

                  <input
                    className="ds-fe-input mapPoint mapInput"
                    name="postcode"
                    type="hidden"
                    ref={
                      registerRef(0)
                        ? register({
                            required: 'Please add a point', // Add a required validation rule
                          })
                        : unregister('postcode')
                    }
                  />
                  <input
                    className="ds-fe-input searchAddress mapInput"
                    name="address"
                    type="text"
                    onChangeCapture={checkBoxesChangeHandler}
                    style={{ display: 'none' }}
                    ref={registerRef(1) ? register : unregister('address')}
                  />
                  <button type="button" className={s.searchButton} onClick={() => {}}>
                    <svg id="search-button" className="ds-btn__icon">
                      <use xlinkHref="#ds-general-search" href="#ds-general-search" />
                    </svg>

                    <svg
                      id="hide-search-button"
                      style={{ display: 'none' }}
                      className="ds-btn__icon"
                    >
                      <use xlinkHref="#ds-general-cross" href="#ds-general-cross" />
                    </svg>
                  </button>
                </div>
              </label>
            </div>
            {/* If there is an error (and error is a string) show here */}
            {errors.postcode && (
              <div className="ds-fe-group ds-fe-group--error">
                <span
                  className="ds-fe-error-message"
                  dangerouslySetInnerHTML={{ __html: sanitize(errors.postcode.message) }}
                />
              </div>
            )}
            {postcode !== null && typeof postcode !== 'undefined' && (
              <>
                <h3>Current location</h3>
                <GetMap lat={getCoords(postcode)[1]} lang={getCoords(postcode)[0]} />
                <h3>Replace the location</h3>
                <input name="pointChanged" ref={fieldValidation} value="yes" type="hidden" />
              </>
            )}
            <MapView lat={getCoords(postcode)[1]} lang={getCoords(postcode)[0]} />
            <br />
          </div>
        )}
      </>
      {!mapView && (
        <>
          <Input
            className="ds-col-1 ds-col-lg-3-4"
            name="BusServiceNumber"
            label={busNumberLabel}
            autocomplete="bus-number"
            fieldValidation={register({
              required: `Please enter bus number`,
            })}
          />
          <Dropdown
            name="BusStation"
            options={options}
            label="Bus Station"
            autocomplete="incident-type"
            defaultValue={station}
            fieldValidation={register({
              required: false,
            })}
          />
          <Input
            className="ds-col-1 ds-col-lg-3-4"
            name="BusFleetNumber"
            label="Bus fleet number"
            fieldValidation={register({
              required: false,
            })}
          />
          <Input
            className="ds-col-1 ds-col-lg-3-4"
            name="TravelDirection"
            label={travelDirectionLabel}
            autocomplete="travel-direction"
            fieldValidation={register({
              required: `Please enter direction of travel`,
            })}
          />
          <Input
            className="ds-col-1 ds-col-lg-3-4"
            name="Town"
            label={travelAreaLabel}
            autocomplete="travel-area"
            fieldValidation={register({
              required: `Please enter town or area`,
            })}
          />
          <Input
            className="ds-col-1 ds-col-lg-3-4"
            name="RoadName"
            label={streetLabel}
            autocomplete="street-name"
            fieldValidation={register({
              required: `Please enter street name`,
            })}
          />
        </>
      )}
    </div>
  );
};

export default Address;
