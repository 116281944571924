import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import dompurify from 'dompurify';

// Import contexts
import { useFormContext, useForm } from 'react-hook-form';
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import DateInput from './DateInput.js/DateInput';

const Date = ({ autoCompletPrefix, fieldValidation, name, label }) => {
  const [, formDispatch] = useContext(FormDataContext); // Get the state of form data from FormDataContext
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext
  const { errors, trigger } = useFormContext();
  const { sanitize } = dompurify;

  const [stateDay, stateMonth, stateYear] = formDataState.formData[name]
    ? formDataState.formData[name].split('-')
    : ['', '', ''];

  // State used for capturing date fields onChange below (we use these to validate against below)
  const [day, setDay] = useState(stateDay);
  const [month, setMonth] = useState(stateMonth);
  const [year, setYear] = useState(stateYear);
  const [date, setDate] = useState(formDataState.formData[name]);
  const [dates, setDates] = useState(formDataState.formData[name]);
  const { register, setValue } = useForm();

  const handleChange = (e) => {
    const { value } = e.target;

    // Switch on the input name, depending on name then update the relevant var
    switch (e.target.name) {
      case `${name}Day`:
        // If value is less than ten and greater than 0 (1-9) and is only 1 in length (so not 08)
        if (value < 10 && value > 0 && value.length === 1) {
          setDay(0 + value); // Then prepend a 0 to it to make it a valid day
        } else {
          setDay(value);
        }
        break;
      case `${name}Month`:
        // If value is less than ten and greater than 0 (1-9) and is only 1 in length (so not 08)
        if (value < 10 && value > 0 && value.length === 1) {
          setMonth(0 + value); // Then prepend a 0 to it to make it a valid month
        } else {
          setMonth(value);
        }
        break;
      default:
        setYear(value);
    }
  };

  useEffect(() => {
    if (day && month && year) {
      setDate(`${day}-${month}-${year}`);
      setDates(`${year}${month}${day}T`);
      formDispatch({
        type: 'UPDATE_FORM_DATA',
        payload: { DateFrom: `${year}${month}${day}` },
      });
    } // Set date state to current yyyymmdd set by user (would do it in handleChange event but it falls out of sync)
  }, [day, month, year, setDate, setDates]);

  // Trigger validation every time date has been updated
  useEffect(() => {
    if (date) trigger();
  }, [date, trigger]);

  return (
    <>
      {/* If there is an error, show here */}
      {errors[name] && <span className="ds-fe-error-message">{errors[name].message}</span>}

      <div className={`ds-fe-group ${errors[name] ? 'ds-fe-group--error' : ''}`}>
        {label && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            className="ds-fe-label"
            htmlFor={name}
            dangerouslySetInnerHTML={{ __html: sanitize(label) }}
          />
        )}
        <div className="ds-col-1-2 ds-col-sm-1-12 ds-m-r-md">
          <DateInput
            autoComplete={autoCompletPrefix ? `${autoCompletPrefix}day` : 'day'}
            dateType="Day"
            defaultValue={day}
            name={name}
            label={label}
            onChange={handleChange}
            maxLength="2"
          />
        </div>
        <div className="ds-col-1-2 ds-col-sm-1-12 ds-m-r-md">
          <DateInput
            autoComplete={autoCompletPrefix ? `${autoCompletPrefix}month` : 'month'}
            dateType="Month"
            defaultValue={month}
            name={name}
            label={label}
            onChange={handleChange}
            maxLength="2"
          />
        </div>
        <div className="ds-col-1-2 ds-col-sm-1-8">
          <DateInput
            autoComplete={autoCompletPrefix ? `${autoCompletPrefix}year` : 'year'}
            dateType="Year"
            defaultValue={year}
            name={name}
            label={label}
            onChange={handleChange}
            maxLength="4"
          />
        </div>
      </div>
      <input name={name} ref={fieldValidation} value={date || ''} type="hidden" />
    </>
  );
};

Date.propTypes = {
  autoCompletPrefix: PropTypes.string,
  fieldValidation: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

Date.defaultProps = {
  autoCompletPrefix: '',
  fieldValidation: null,
};

export default Date;
