import React, { useRef } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import Radios from 'components/shared/FormElements/Radios/Radios';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
// import useFormData from '../../../useFormData';

const Consent = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  const radioButtons = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
  ];

  const title = 'Did you get consent?';

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 3 of 3" description="Your response" />
      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{title}</h2>
        </legend>

        <Radios
          name="GetConsent"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
        />
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default Consent;
