import React, { useState } from 'react';
import Intro from 'components/Introduction/Introduction';
import Form from 'components/Form/Form';

// Import contexts
import { FormDataProvider } from 'globalState/FormDataContext';

import SubmitSuccess from 'components/Form/SubmitConfirmation/Success';
import SubmitFail from 'components/Form/SubmitConfirmation/Error';
import './map.css';

function App() {
  const [isFormStarted, setIsFormStarted] = useState(false);
  const [isRecoverLinkPressed, setIsRecoverLinkPressed] = useState(false);
  const [formSubmitStatus, setFormSubmitStatus] = useState(null);

  return (
    <>
      <main className="ds-container ds-container--main ds-p-b-lg ds-grid">
        {!isFormStarted ? (
          <Intro setIsFormStarted={setIsFormStarted} />
        ) : (
          <FormDataProvider>
            {isFormStarted && formSubmitStatus === null && (
              <Form
                setFormSubmitStatus={setFormSubmitStatus}
                formSubmitStatus={formSubmitStatus}
                isRecoverLinkPressed={isRecoverLinkPressed}
                setIsRecoverLinkPressed={setIsRecoverLinkPressed}
                setIsFormStarted={setIsFormStarted}
              />
            )}

            {formSubmitStatus === true && <SubmitSuccess />}
            {formSubmitStatus === false && <SubmitFail />}
          </FormDataProvider>
        )}
      </main>
    </>
  );
}

export default App;
