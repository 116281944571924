import React, { useRef, useContext } from 'react';

// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Context
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import Radios from 'components/shared/FormElements/Radios/Radios';
import Input from 'components/shared/FormElements/Input/Input';

import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import useFormData from '../../../useFormData';

const Contact = () => {
  const [, formDispatch] = useContext(FormDataContext); // Get the state of form data from FormDataContext
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  // Logic used to validate fields
  const fieldValidation = (name) => {
    return register({ required: `${name} is required` });
  };
  const [formDataState] = useContext(FormDataContext);

  // locker number input src
  const lockerInputSrc = (
    <div className="ds-m-l-xl">
      <Input
        className="ds-col-1 ds-col-lg-3-4"
        name="LockerNumber"
        label="What was the locker number?"
        fieldValidation={fieldValidation('Locker number')}
      />
    </div>
  );

  // Add locker input if true
  let lockerInput;

  const cctvData = (e) => {
    formDispatch({
      type: 'UPDATE_FORM_DATA',
      payload: { CCTVControlRoom: e.target.value },
    });
  };

  // display the locker input
  if (formDataState.formData.CCTVControlRoom === 'Yes') {
    lockerInput = lockerInputSrc;
  } else {
    formDataState.formData.LockerNumber = null;
  }

  const radioButtons = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
  ];

  // Check if it is an existing user already
  const { ExistingUser } = useFormData();
  const title = 'Did you contact the CCTV control room to monitor the incident?';

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      {/* Subsection */}
      {!ExistingUser && <SectionStepInfo section="Section 3 of 3" />}

      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{title}</h2>
        </legend>

        <Radios
          name="CCTVControlRoom"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
          change={cctvData}
        />
        {lockerInput}
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default Contact;
