import React, { useContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { FormDataContext } from 'globalState/FormDataContext';
import Step1Enquiry from './Step1/EnquirySelection';
import PublicStep2Who from './Step2/Public/Who';
import PublicStep3Email from './Step3/Public/YourPublicEmail';
import PublicStep4PhoneNumber from './Step4/Public/PublicPhoneNumber';
import PublicStep5IncidentDate from './Step5/Public/IncidentDate';
import PublicStep6IncidentMode from './Step6/Public/IncidentMode';
import PublicStep7BusIncidentLocation from './Step7/Public/Bus/IncidentLocation';
import PublicStep7TrainIncidentLocation from './Step7/Public/Train/IncidentLocation';
import PublicStep7TramIncidentLocation from './Step7/Public/Tram/IncidentLocation';
import PublicStep8TypeOfIncident from './Step8/Public/IncidentType';
import PublicStep9IncidentDetails from './Step9/Public/IncidentDetails';
import PublicStep10OffenderPhoto from './Step10/Public/OffenderPhoto';
import PublicStep11UploadOffenderPhoto from './Step11/Public/UploadOffenderPhoto';
import StaffStep2Who from './Step2/Staff/Who';
import StaffStep3Email from './Step3/Staff/YourEmail';
import StaffStep4PhoneNumber from './Step4/Staff/PhoneNumber';
import StaffStep5IncidentDate from './Step5/Staff/IncidentDate';
import StaffStep6IncidentMode from './Step6/Staff/IncidentMode';
import StaffStep7BusIncidentLocation from './Step7/Staff/Bus/IncidentLocation';
import StaffStep7TrainIncidentLocation from './Step7/Staff/Train/IncidentLocation';
import StaffStep7TramIncidentLocation from './Step7/Staff/Tram/IncidentLocation';
import StaffStep8TypeOfIncident from './Step8/Staff/IncidentType';
import StaffStep9IncidentDetails from './Step9/Staff/IncidentDetails';
import StaffStep10OffenderPhoto from './Step10/Staff/OffenderPhoto';
// import StaffStep11UploadOffenderPhoto from './Step11/Staff/UploadOffenderPhoto';
import StaffStep12CCTV from './Step12/Staff/Contact';
import StaffStep13BodyCam from './Step13/Staff/Bodycam';
import StaffStep14EngagePolitely from './Step14/Staff/EngagePolitely';
import StaffStep15Support from './Step15/Staff/Support';
import StaffStep16Safeguarding from './Step16/Staff/Safeguarding';
import StaffStep17Consent from './Step17/Staff/Consent';
import StaffStep18ReportToPolice from './Step18/Staff/ReportToPolice';
import Confirmation from './StepConfirm/StepConfirm';
import s from './Form.module.scss';

const Form = ({ formSubmitStatus, setFormSubmitStatus, isRecoverLinkPressed }) => {
  const formRef = useRef();
  const scrollToTopOfSummary = useCallback(() => {
    const page = document.getElementsByClassName('ds-html')[0];
    const pageOffset = page.scrollTop;
    const formOffset = formRef.current.offsetTop;
    if (formOffset >= pageOffset) return;
    page.scrollTo(0, formOffset - 20);
  }, []);

  const [formDataState, formDataDispatch] = useContext(FormDataContext);
  const { currentStep } = formDataState;
  const { ReportedByType, TransportMode, OffenderImages } = formDataState.formData;

  const methods = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (currentStep === 9) scrollToTopOfSummary();
  }, [currentStep, scrollToTopOfSummary]);

  useEffect(() => {
    if (isRecoverLinkPressed) {
      formDataDispatch({
        type: 'UPDATE_STEP',
        payload: 0,
      });
    }
  }, [formDataDispatch, isRecoverLinkPressed]);

  const debugStepOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

  let stepToGoTo;

  if (currentStep > 1 && currentStep < 20) {
    stepToGoTo = currentStep - 1;
  }

  const renderStep7Component = () => {
    if (ReportedByType === 'Member of the public') {
      if (TransportMode === 'Bus') {
        return <PublicStep7BusIncidentLocation />;
      }
      if (TransportMode === 'Train') {
        return <PublicStep7TrainIncidentLocation />;
      }
      if (TransportMode === 'Tram') {
        return <PublicStep7TramIncidentLocation />;
      }
    }
    return null; // Render nothing if conditions aren't met
  };

  return (
    <>
      {/* pass all methods into the context */}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <div className="ds-col-1 ds-col-md-2-3">
          {stepToGoTo && (
            <div className="ds-col-1 ds-m-b-md">
              <button
                type="button"
                className="ds-btn ds-btn--link"
                onClick={() =>
                  formDataDispatch({
                    type: 'UPDATE_STEP',
                    payload: stepToGoTo,
                  })
                }
              >
                &lt; Back
              </button>
            </div>
          )}

          <div
            className={formSubmitStatus === null ? `${s.formWrapper} ds-p-lg ` : ''}
            ref={formRef}
          >
            {currentStep === 1 && <Step1Enquiry setFormSubmitStatus={setFormSubmitStatus} />}
            {currentStep === 2 && ReportedByType === 'Member of the public' && <PublicStep2Who />}
            {currentStep === 3 && ReportedByType === 'Member of the public' && <PublicStep3Email />}
            {currentStep === 4 && ReportedByType === 'Member of the public' && (
              <PublicStep4PhoneNumber />
            )}
            {currentStep === 5 && ReportedByType === 'Member of the public' && (
              <PublicStep5IncidentDate />
            )}
            {currentStep === 6 && ReportedByType === 'Member of the public' && (
              <PublicStep6IncidentMode />
            )}
            {currentStep === 7 && renderStep7Component()}
            {currentStep === 8 && ReportedByType === 'Member of the public' && (
              <PublicStep8TypeOfIncident />
            )}
            {currentStep === 9 && ReportedByType === 'Member of the public' && (
              <PublicStep9IncidentDetails />
            )}
            {currentStep === 10 && ReportedByType === 'Member of the public' && (
              <PublicStep10OffenderPhoto />
            )}

            {currentStep === 11 &&
              ReportedByType === 'Member of the public' &&
              (OffenderImages === 'Yes' ? (
                <PublicStep11UploadOffenderPhoto />
              ) : (
                <Confirmation
                  setFormSubmitStatus={setFormSubmitStatus}
                  who="Member of the public"
                />
              ))}

            {currentStep === 12 && ReportedByType === 'Member of the public' && (
              <Confirmation setFormSubmitStatus={setFormSubmitStatus} who="Member of the public" />
            )}

            {/* member of staff */}
            {currentStep === 2 && ReportedByType !== 'Member of the public' && <StaffStep2Who />}
            {currentStep === 3 && ReportedByType !== 'Member of the public' && <StaffStep3Email />}
            {currentStep === 4 && ReportedByType !== 'Member of the public' && (
              <StaffStep4PhoneNumber />
            )}
            {currentStep === 5 && ReportedByType !== 'Member of the public' && (
              <StaffStep5IncidentDate />
            )}
            {currentStep === 6 && ReportedByType !== 'Member of the public' && (
              <StaffStep6IncidentMode />
            )}
            {currentStep === 7 &&
              ReportedByType !== 'Member of the public' &&
              TransportMode === 'Bus' && <StaffStep7BusIncidentLocation />}
            {currentStep === 7 &&
              ReportedByType !== 'Member of the public' &&
              TransportMode === 'Train' && <StaffStep7TrainIncidentLocation />}
            {currentStep === 7 &&
              ReportedByType !== 'Member of the public' &&
              TransportMode === 'Tram' && <StaffStep7TramIncidentLocation />}
            {currentStep === 8 && ReportedByType !== 'Member of the public' && (
              <StaffStep8TypeOfIncident />
            )}
            {currentStep === 9 && ReportedByType !== 'Member of the public' && (
              <StaffStep9IncidentDetails />
            )}
            {currentStep === 10 && ReportedByType !== 'Member of the public' && (
              <StaffStep10OffenderPhoto />
            )}

            {currentStep === 11 &&
              ReportedByType !== 'Member of the public' &&
              (OffenderImages === 'Yes' ? (
                <PublicStep11UploadOffenderPhoto />
              ) : (
                <StaffStep12CCTV />
              ))}

            {currentStep === 12 &&
              ReportedByType !== 'Member of the public' &&
              (OffenderImages === 'Yes' ? <StaffStep12CCTV /> : <StaffStep13BodyCam />)}

            {currentStep === 13 &&
              ReportedByType !== 'Member of the public' &&
              (OffenderImages === 'Yes' ? <StaffStep13BodyCam /> : <StaffStep14EngagePolitely />)}

            {currentStep === 14 &&
              ReportedByType !== 'Member of the public' &&
              (OffenderImages === 'Yes' ? <StaffStep14EngagePolitely /> : <StaffStep15Support />)}

            {currentStep === 15 &&
              ReportedByType !== 'Member of the public' &&
              (OffenderImages === 'Yes' ? <StaffStep15Support /> : <StaffStep16Safeguarding />)}

            {currentStep === 16 &&
              ReportedByType !== 'Member of the public' &&
              (OffenderImages === 'Yes' ? <StaffStep16Safeguarding /> : <StaffStep17Consent />)}

            {currentStep === 17 &&
              ReportedByType !== 'Member of the public' &&
              (OffenderImages === 'Yes' ? <StaffStep17Consent /> : <StaffStep18ReportToPolice />)}

            {currentStep === 18 &&
              ReportedByType !== 'Member of the public' &&
              (OffenderImages === 'Yes' ? (
                <StaffStep18ReportToPolice />
              ) : (
                <Confirmation setFormSubmitStatus={setFormSubmitStatus} who="TfWM" />
              ))}

            {currentStep === 19 && ReportedByType !== 'Member of the public' && (
              <Confirmation setFormSubmitStatus={setFormSubmitStatus} who="TfWM" />
            )}
            {/* for testing only */}
            {/* {currentStep === 11 && <SubmitSuccess />}
            {currentStep === 12 && <SubmitError />} */}
          </div>
        </div>

        {process.env.NODE_ENV !== 'production' && (
          <div
            className="ds-col-1 ds-col-md-1-4 ds-p-md"
            style={{
              overflowX: 'auto',
              position: 'fixed',
              right: 0,
            }}
          >
            <pre>{JSON.stringify(formDataState, null, 2)}</pre>
            <br />
            <div className="ds-col-1">
              Select step: {}
              <select
                onChange={(e) =>
                  formDataDispatch({
                    type: 'UPDATE_STEP',
                    payload: +e.target.value,
                  })
                }
                onBlur={(e) =>
                  formDataDispatch({
                    type: 'UPDATE_STEP',
                    payload: +e.target.value,
                  })
                }
                value={currentStep}
              >
                {debugStepOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </FormProvider>
    </>
  );
};

Form.propTypes = {
  formSubmitStatus: PropTypes.bool,
  setFormSubmitStatus: PropTypes.func.isRequired,
  setIsFormStarted: PropTypes.func.isRequired,
  isRecoverLinkPressed: PropTypes.bool,
  setIsRecoverLinkPressed: PropTypes.func.isRequired,
};

Form.defaultProps = {
  formSubmitStatus: null,
  isRecoverLinkPressed: false,
};

export default Form;
