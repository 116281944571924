import React, { useRef, useContext } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Context
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import Radios from 'components/shared/FormElements/Radios/Radios';
import Textarea from 'components/shared/FormElements/Input/textarea';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import InsetText from 'components/shared/InsetText/InsetText';
// import useFormData from '../../../useFormData';

const Support = () => {
  const [, formDispatch] = useContext(FormDataContext); // Get the state of form data from FormDataContext
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  // Logic used to validate fields
  const fieldValidation = (name) => {
    return register({ required: `${name} is required` });
  };
  const [formDataState] = useContext(FormDataContext);

  // locker number input src
  const supportSrc = (
    <div className="ds-m-l-xl">
      <Textarea
        className="ds-col-1 ds-col-lg-3-4"
        name="WhatSupport"
        label="What support did they ask for?"
        fieldValidation={fieldValidation('What support did they ask for?')}
      />
    </div>
  );

  // Add locker input if true
  let support;

  const bodycamData = (e) => {
    formDispatch({
      type: 'UPDATE_FORM_DATA',
      payload: { AskGroupSupport: e.target.value },
    });
  };

  // display the locker input
  if (formDataState.formData.AskGroupSupport === 'Yes') {
    support = supportSrc;
  } else {
    formDataState.formData.WhatSupport = null;
  }

  const radioButtons = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
  ];

  const title = 'Did you ask the person or group if they needed help or support?';

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 3 of 3" description="Your response" />
      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{title}</h2>
        </legend>

        <Radios
          name="AskGroupSupport"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
          change={bodycamData}
        />
        {support}
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default Support;
