import React, { useContext, useState, useRef } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import IncidentTypeList from 'components/Form/Shared/IncidentType/IncidentTypeList';
// import useFormData from '../../../useFormData';

// Import contexts
import { FormDataContext } from 'globalState/FormDataContext';

const IncidentType = () => {
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  const date = useState(formDataState.formData.DateFrom);
  const TimeOfIncidentF = useState(formDataState.formData.TimeOfIncidentFrom);
  const TimeOfIncidentT = useState(formDataState.formData.TimeOfIncidentTo);

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 2 of 2" description="About the incident" />

      {/* Show generic error message */}
      {showGenericError}

      {/* Show list */}
      <IncidentTypeList />

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default IncidentType;
