import React, { useState, useRef, useContext } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import Radios from 'components/shared/FormElements/Radios/Radios';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
// import useFormData from '../../../useFormData';

const IncidentMode = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  const radioButtons = [
    { text: 'Bus', value: 'Bus' },
    { text: 'Train', value: 'Train' },
    { text: 'Tram', value: 'Tram' },
  ];

  // Check it we are facing an existing user

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 2 of 2" description="About the incident" />
      {/* Show generic error message */}
      {showGenericError}
      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">Which mode of transport did the incident happen on?</h2>
        </legend>

        <Radios
          name="TransportMode"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
        />
      </fieldset>
      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default IncidentMode;
