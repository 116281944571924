import React, { useRef, useContext } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import Input from 'components/shared/FormElements/Input/Input';
import Dropdown from 'components/shared/FormElements/Dropdown/Dropdown';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import { useFormContext } from 'react-hook-form';
// import useFormData from '../../../useFormData';

const IncidentLocation = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)
  const { register } = useFormContext(); // Get useForm methods
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext

  // Labels used on inputs and for validation
  const trainRouteLabel = 'Train route <br> For example, Birmingham to Wolverhampton';
  const detailsLabel = 'Provide as much detail as possible';

  const stationLabel = 'Nearest Station';

  const options = [
    { label: '', value: '' },
    { label: 'Acocks Green', value: 'Acocks Green' },
    { label: 'Adderley Park', value: 'Adderley Park' },
    { label: 'Aston', value: 'Aston' },
    { label: 'Berkswell', value: 'Berkswell' },
    { label: 'Bescot Stadium', value: 'Bescot Stadium' },
    { label: 'Birmingham International', value: 'Birmingham International' },
    { label: 'Birmingham Moor Street', value: 'Birmingham Moor Street' },
    { label: 'Birmingham New Street', value: 'Birmingham New Street' },
    { label: 'Birmingham Snow Hill', value: 'Birmingham Snow Hill' },
    { label: 'Blake Street', value: 'Blake Street' },
    { label: 'Bloxwich', value: 'Bloxwich' },
    { label: 'Bloxwich North', value: 'Bloxwich North' },
    { label: 'Bordesley', value: 'Bordesley' },
    { label: 'Bournville', value: 'Bournville' },
    { label: 'Butlers Lane', value: 'Butlers Lane' },
    { label: 'Canley', value: 'Canley' },
    { label: 'Chester Road', value: 'Chester Road' },
    { label: 'Coseley', value: 'Coseley' },
    { label: 'Coventry', value: 'Coventry' },
    { label: 'Coventry Arena', value: 'Coventry Arena' },
    { label: 'Cradley Heath', value: 'Cradley Heath' },
    { label: 'Dorridge', value: 'Dorridge' },
    { label: 'Duddeston', value: 'Duddeston' },
    { label: 'Dudley Port', value: 'Dudley Port' },
    { label: 'Earlswood', value: 'Earlswood' },
    { label: 'Erdington', value: 'Erdington' },
    { label: 'Five Ways', value: 'Five Ways' },
    { label: 'Four Oaks', value: 'Four Oaks' },
    { label: 'Gravelly Hill', value: 'Gravelly Hill' },
    { label: 'Hall Green', value: 'Hall Green' },
    { label: 'Hampton-in-Arden', value: 'Hampton-in-Arden' },
    { label: 'Hamstead', value: 'Hamstead' },
    { label: 'Jewellery Quarter', value: 'Jewellery Quarter' },
    { label: 'Kings Norton', value: 'Kings Norton' },
    { label: 'Langley Green', value: 'Langley Green' },
    { label: 'Lea Hall', value: 'Lea Hall' },
    { label: 'Longbridge', value: 'Longbridge' },
    { label: 'Lye', value: 'Lye' },
    { label: 'Marston Green', value: 'Marston Green' },
    { label: 'Northfield', value: 'Northfield' },
    { label: 'Old Hill', value: 'Old Hill' },
    { label: 'Olton', value: 'Olton' },
    { label: 'Perry Barr', value: 'Perry Barr' },
    { label: 'Rowley Regis', value: 'Rowley Regis' },
    { label: 'Sandwell and Dudley', value: 'Sandwell and Dudley' },
    { label: 'Selly Oak', value: 'Selly Oak' },
    { label: 'Shirley', value: 'Shirley' },
    { label: 'Small Heath', value: 'Small Heath' },
    { label: 'Smethwick Galton Bridge', value: 'Smethwick Galton Bridge' },
    { label: 'Smethwick Rolfe Street', value: 'Smethwick Rolfe Street' },
    { label: 'Solihull', value: 'Solihull' },
    { label: 'Spring Road', value: 'Spring Road' },
    { label: 'Stechford', value: 'Stechford' },
    { label: 'Stourbridge Junction', value: 'Stourbridge Junction' },
    { label: 'Stourbridge Town', value: 'Stourbridge Town' },
    { label: 'Sutton Coldfield', value: 'Sutton Coldfield' },
    { label: 'Tame Bridge Parkway', value: 'Tame Bridge Parkway' },
    { label: 'The Hawthorns', value: 'The Hawthorns' },
    { label: 'Tile Hill', value: 'Tile Hill' },
    { label: 'Tipton', value: 'Tipton' },
    { label: 'Tyseley', value: 'Tyseley' },
    { label: 'University', value: 'University' },
    { label: 'Walsall', value: 'Walsall' },
    { label: 'Whitlocks End', value: 'Whitlocks End' },
    { label: 'Widney Manor', value: 'Widney Manor' },
    { label: 'Witton', value: 'Witton' },
    { label: 'Wolverhampton', value: 'Wolverhampton' },
    { label: 'Wylde Green', value: 'Wylde Green' },
    { label: 'Wythall', value: 'Wythall' },
    { label: 'Yardley Wood', value: 'Yardley Wood' },
  ];

  const areaOptions = [
    { label: '', value: '' },
    { label: 'Platform', value: 'Platform' },
    { label: 'Car park', value: 'Car park' },
    { label: 'Cycle rack', value: 'Cycle rack' },
    { label: 'Concourse', value: 'Concourse' },
    { label: 'Bridge', value: 'Bridge' },
    { label: 'Booking office', value: 'Booking office' },
    { label: 'Ticket barrier', value: 'Ticket barrier' },
    { label: 'Waiting room / shelter', value: 'Waiting room / shelter' },
    { label: 'Stairs', value: 'Stairs' },
    { label: 'Toilet', value: 'Toilet' },
    { label: 'Lift', value: 'Lift' },
  ];

  const railStation = formDataState.formData.RailStation
    ? formDataState.formData.RailStation
    : null;
  const railArea = formDataState.formData.RailStationArea
    ? formDataState.formData.RailStationArea
    : null;

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 2 of 2" description="About the incident" />

      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">Where did the incident happen?</h2>
          <p>{detailsLabel}</p>
        </legend>

        <Input
          className="ds-col-1 ds-col-lg-3-4"
          name="TrainRoute"
          label={trainRouteLabel}
          autocomplete="train-route"
          fieldValidation={register({
            required: `Please enter train route`,
          })}
        />
        {/* <Input
          className="ds-col-1 ds-col-lg-3-4"
          name="RailStation"
          label={stationLabel}
          autocomplete="nearest-train-station"
          fieldValidation={register({
            required: `Please enter nearest train station name`,
          })}
        /> */}

        <Dropdown
          name="RailStation"
          options={options}
          label="Nearest rail station"
          defaultValue={railStation}
          fieldValidation={register({
            required: `Please select nearest tram station`,
          })}
        />

        <Dropdown
          name="RailStationArea"
          options={areaOptions}
          label="What area of the station/stop did the incident occur?"
          defaultValue={railArea}
          fieldValidation={register({
            required: false,
          })}
        />
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default IncidentLocation;
