import React from 'react';

function Success() {
  // eslint-disable-next-line no-unused-vars

  const title = 'We’ve received your incident report';

  return (
    <div className="ds-container-alerts-sign-up">
      <div className="ds-grid ds-col-1 ds-col-md-2-3">
        <div className="ds-msg-summary ds-msg-summary--success-fill">
          <h3 className="ds-msg-summary__info">{title}</h3>
        </div>

        <div className="ds-col-1">
          <h2>What happens next</h2>
          <p>
            It can take up to 5 working days to process a request. It may take longer if we need
            more information from you.
          </p>
          <p>
            If you need to contact us, email SaferTravelASBTeam@wmca.org.uk. You can also text us on
            81018.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Success;
