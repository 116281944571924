import React from 'react';
import PropTypes from 'prop-types';
import dompurify from 'dompurify';
// Import contexts
import { useFormContext } from 'react-hook-form';

const { sanitize } = dompurify;

const Dropdown = ({ name, classes, label, fieldValidation, options, defaultValue }) => {
  const { errors } = useFormContext();
  return (
    <div className={`ds-fe-group ${errors[name] && 'ds-fe-group--error'} ${classes}`}>
      <div className="ds-fe-dropdown">
        {label && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            className="ds-fe-label"
            htmlFor={name}
            dangerouslySetInnerHTML={{ __html: sanitize(label) }}
          />
        )}
        {/* Loop through options and display each option button */}
        <select
          className="ds-fe-dropdown__select"
          id={name}
          name={name}
          ref={fieldValidation}
          defaultValue={defaultValue}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

// PropTypes
Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string, PropTypes.string)).isRequired,
  fieldValidation: PropTypes.func,
  classes: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
};

Dropdown.defaultProps = {
  fieldValidation: null,
  classes: null,
  label: null,
  defaultValue: null,
};

export default Dropdown;
