import React, { useContext } from 'react';
// Import contexts
import { useFormContext } from 'react-hook-form';
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import DateInput from 'components/shared/FormElements/Date/Date';
import TimeInput from 'components/shared/FormElements/Time/Time';
// Import helper functions
import dateValidationHelpers from 'components/shared/FormElements/Date/dateValidationHelpers';

const DateAndTime = () => {
  const [, formDispatch] = useContext(FormDataContext); // Get the state of form data from FormDataContext
  const { register } = useFormContext(); // Custom hook for handling validation (errors etc)
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext

  const { dateRegex, timeRegex, dateRegexSimple, dateInPastArrow, dateInPastArrow2 } =
    dateValidationHelpers;

  const dateValidation = register({
    required: 'Enter a Date of the incident',
    pattern: {
      value: dateRegex,
      message: 'Enter Date of incident in the correct format, for example 18 03 2020',
    },
    validate: {
      dateInPastArrow: (value) => dateInPastArrow(value) || 'Date of incident must be in the past',
    },
  });

  const fieldValidation = register({
    required: 'Enter a Date of the incident',
  });

  const timeFromValidation = register({
    required: 'Enter a start time of the incident',
    pattern: {
      value: timeRegex,
      message: 'Enter start time of incident in the correct format, for example 6 30',
    },
  });

  const timeToValidation = register({
    required: 'Enter an end time of the incident',
    pattern: {
      value: timeRegex,
      message: 'Enter end time of incident in the correct format, for example 6 45',
    },
    // validate: {
    //   dateInPastArrow: (value) =>
    //     dateInPastArrow(value) || 'Time of incident must be after the start time',
    // },
  });

  // set am/pm default value
  const fromAmPm = formDataState.formData.TimeOfIncidentFromAmPm
    ? formDataState.formData.TimeOfIncidentFromAmPm
    : 'am';

  const toAmPm = formDataState.formData.TimeOfIncidentToAmPm
    ? formDataState.formData.TimeOfIncidentToAmPm
    : 'am';

  const timFrom = formDataState.formData.TimeOfIncidentFrom;

  return (
    <>
      <DateInput
        name="DateOfIncident"
        label="Date*"
        autoCompletPrefix="iday-"
        fieldValidation={dateValidation}
      />
      <TimeInput
        name="TimeOfIncidentFrom"
        label="Incident start time* </br> For example, 9:30 am or 2:55pm"
        autoCompletPrefix="itime-"
        fieldValidation={timeFromValidation}
        defaultValue={fromAmPm}
      />
      <TimeInput
        name="TimeOfIncidentTo"
        label="Incident end time*"
        autoCompletPrefix="itime-"
        fieldValidation={timeToValidation}
        defaultValue={toAmPm}
      />
    </>
  );
};

export default DateAndTime;
