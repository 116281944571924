/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
import useSubmitForm from '../useSubmitForm';
import PublicSummarySection from './Public/StepSummarySection';
import StaffSummarySection from './Staff/StepSummarySection';
import StepConsentForm from './StepConsentForm';
import { FormDataContext } from '../../../globalState/FormDataContext';
import Button from '../../shared/Button/Button';

function StepConfirm({ setFormSubmitStatus, who }) {
  const [formDataState, formDataDispatch] = useContext(FormDataContext);
  // Get handleSubmit fn and isFetching from custom hook which handles submitting data to API (this is used in the last step[4])
  const { handleSubmit, isFetching, APIErrorMessage } = useSubmitForm(setFormSubmitStatus);
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)
  const { setValue } = useForm();

  const fieldValidation = register({
    required: 'Enter a Date of the incident',
  });

  const loc = `https://www.google.com/maps/place/${sessionStorage.getItem(
    'latitude'
  )},${sessionStorage.getItem('longitude')}`;

  // set location details
  let location = loc;
  if (formDataState.formData.pointChanged === 'yes') {
    location = `Map Point: ${loc}`;
  } else if (
    formDataState.formData.TransportMode === 'Bus' &&
    formDataState.formData.postcode != null
  ) {
    location = `Map Point: ${loc}`;
  } else if (
    formDataState.formData.TrainRoute != null &&
    formDataState.formData.TransportMode === 'Train'
  ) {
    location = `Train Route: ${formDataState.formData.TrainRoute}`;
  } else if (
    formDataState.formData.TravelDirection != null &&
    formDataState.formData.TransportMode === 'Bus'
  ) {
    location = `Bus number: ${formDataState.formData.BusServiceNumber} | Travel direction: ${formDataState.formData.TravelDirection} | Travel area: ${formDataState.formData.Town} | Street name: ${formDataState.formData.RoadName}`;
  } else if (
    formDataState.formData.TramDirection != null &&
    formDataState.formData.TransportMode === 'Tram'
  ) {
    location = `Tram Direction: ${formDataState.formData.TramDirection} | Nearest Tram Station: ${formDataState.formData.TramStop}`;
  } else {
    location = `N/A`;
  }

  // convert time to 24hr time
  const timeFrom = `${formDataState.formData.TimeOfIncidentFrom} ${formDataState.formData.TimeOfIncidentFromAmPm}`;
  const timeTo = `${formDataState.formData.TimeOfIncidentTo} ${formDataState.formData.TimeOfIncidentToAmPm}`;

  function convertTo24HourFormat(timeString) {
    const [time, period] = timeString.split(' ');
    const [hour, minute] = time.split(':');
    let formattedHour = parseInt(hour, 10);

    if (period === 'PM') {
      formattedHour += 12;
    }

    // add another 0 if hour is less than 10
    function hoursWithZeros(dt) {
      if (dt < 10) {
        return `0${dt}`;
      }
      if (dt === 24) {
        return `00`;
      }
      return dt;
    }

    // add another 0 if minute is 0
    function minutesWithLeadingZeros(dt) {
      if (dt === '0') {
        return '00';
      }
      return dt;
    }

    const min = minutesWithLeadingZeros(minute);
    const hr = hoursWithZeros(formattedHour);
    return `${hr}:${min}`;
  }

  const formattedFromTime = convertTo24HourFormat(timeFrom);
  const formattedToTime = convertTo24HourFormat(timeTo);

  if (formattedFromTime !== null) {
    setValue(formattedFromTime, convertTo24HourFormat(timeFrom));
  }

  if (formattedToTime !== null) {
    setValue(formattedToTime, convertTo24HourFormat(timeTo));
  }

  useEffect(() => {
    if (
      formDataState.currentStep === 11 ||
      formDataState.currentStep === 12 ||
      formDataState.currentStep === 18 ||
      formDataState.currentStep === 19
    ) {
      formDataDispatch({
        type: 'REACHED_CONFIRMATION',
        payload: true,
      });
    }
  }, [formDataDispatch, formDataState.currentStep]);

  return (
    <form onSubmit={handleSubmit} data-private>
      {/* If we get any errors back from the server, show here */}
      {APIErrorMessage && <span className="ds-fe-error-message">{APIErrorMessage}</span>}
      {/* Set updated data */}
      <input name="LocationDetails" ref={fieldValidation} value={location} type="hidden" />
      <input
        name="DateOfIncidentFrom"
        ref={fieldValidation}
        value={`${formDataState.formData.DateFrom}T${formattedFromTime}` || 'n/a'}
        type="hidden"
      />
      <input
        name="DateOfIncidentTo"
        ref={fieldValidation}
        value={`${formDataState.formData.DateFrom}T${formattedToTime}` || 'n/a'}
        type="hidden"
      />

      {/* are you public or staff? */}
      {who === 'Member of the public' ? <PublicSummarySection /> : <StaffSummarySection />}

      <div className="ds-col-1">
        {formDataState.formData.EmailAlert === 'yes' && !formDataState.formData.ExistingUser && (
          <StepConsentForm />
        )}
        <Button
          btnClass="ds-btn--start ds-m-t-lg ds-col-1 ds-col-sm-auto"
          disabled={isFetching}
          iconRight="general-chevron-right"
          isFetching={isFetching}
          type="submit"
          text="Accept and send"
        />
      </div>
    </form>
  );
}

StepConfirm.propTypes = {
  setFormSubmitStatus: PropTypes.func.isRequired,
  who: PropTypes.string.isRequired,
};

export default StepConfirm;
