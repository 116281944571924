import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import dompurify from 'dompurify';

// Import contexts
import { useFormContext } from 'react-hook-form';
import { FormDataContext } from 'globalState/FormDataContext';
// Import components
import Dropdown from 'components/shared/FormElements/Dropdown/Dropdown';
import TimeInput from '../Date/DateInput.js/DateInput';

const Time = ({ autoCompletPrefix, fieldValidation, name, label, defaultValue }) => {
  const [, formDispatch] = useContext(FormDataContext); // Get the state of form data from FormDataContext
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext
  const { errors, trigger } = useFormContext();
  const { register } = useFormContext(); // Get useForm methods
  const { sanitize } = dompurify;
  const [stateHour, stateMinute] = formDataState.formData[name]
    ? formDataState.formData[name].split(':')
    : ['', ''];

  // State used for capturing date fields onChange below (we use these to validate against below)
  const [hour, setHour] = useState(stateHour);
  const [minute, setMinute] = useState(stateMinute);
  const [time, setTime] = useState(formDataState.formData[name]);
  const options = [
    { label: 'am', value: 'AM' },
    { label: 'pm', value: 'PM' },
  ];

  // const firstDate = new Date('2024-01-02T03:24:00');
  // const secondDate = new Date('2024-01-02T04:30:00');

  // // Get the time in milliseconds for each date
  // const firstTime = firstDate.getTime();
  // const secondTime = secondDate.getTime();

  // // Compare the time values
  // if (firstTime < secondTime) {
  //   console.log('firstDate is earlier than secondDate');
  // } else if (firstTime > secondTime) {
  //   console.log('firstDate is later than secondDate');
  // } else {
  //   console.log('firstDate are  secondDate');
  // }

  const handleChange = (e) => {
    const { value } = e.target;
    // Switch on the input name, depending on name then update the relevant var
    switch (e.target.name) {
      case `${name}Hour`:
        // If value is less than ten and greater than 0 (1-9) and is only 1 in length (so not 08)
        if (value < 10 && value > 0 && value.length === 1) {
          setHour(0 + value); // Then prepend a 0 to it to make it a valid day
        } else {
          setHour(value);
        }
        break;
      case `${name}Minute`:
        // If value is less than ten and greater than 0 (1-9) and is only 1 in length (so not 08)
        if (value < 10 && value > 0 && value.length === 1) {
          setMinute(0 + value); // Then prepend a 0 to it to make it a valid month
        } else {
          setMinute(value);
        }
        break;
      default:
      // Handle other cases or no-op
    }
  };

  useEffect(() => {
    if (minute && hour) {
      setTime(`${hour}:${minute}`);
      if (name === 'TimeOfIncidentFrom') {
        formDispatch({
          type: 'UPDATE_FORM_DATA',
          payload: { TimeOfIncidentFromHour: `${hour}` },
        });
        formDispatch({
          type: 'UPDATE_FORM_DATA',
          payload: { TimeOfIncidentFromMinute: `${minute}` },
        });
      }
      if (name === 'TimeOfIncidentTo') {
        formDispatch({
          type: 'UPDATE_FORM_DATA',
          payload: { TimeOfIncidentToHour: `${hour}` },
        });
        formDispatch({
          type: 'UPDATE_FORM_DATA',
          payload: { TimeOfIncidentToMinute: `${minute}` },
        });
      }
    } // Set date state to current yyyy-mm-dd set by user (would do it in handleChange event but it falls out of sync)
  }, [hour, minute, setTime]);

  // Trigger validation everytime time has been updated
  useEffect(() => {
    if (time) trigger();
  }, [time, trigger]);

  return (
    <>
      {/* If there is an error, show here */}
      {errors[name] && <span className="ds-fe-error-message">{errors[name].message}</span>}

      <div className={`ds-fe-group ${errors[name] ? 'ds-fe-group--error' : ''}`}>
        {label && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            className="ds-fe-label"
            htmlFor={name}
            dangerouslySetInnerHTML={{ __html: sanitize(label) }}
          />
        )}
        <div className="ds-col-1-2 ds-col-sm-1-12 ds-m-r-md">
          <TimeInput
            autoComplete={autoCompletPrefix ? `${autoCompletPrefix}hour` : 'hour'}
            dateType="Hour"
            defaultValue={hour}
            name={name}
            label={label}
            onChange={handleChange}
            maxLength="2"
            fieldValidation={register({
              required: `Hour`,
            })}
          />
        </div>
        <div className="ds-col-1-2 ds-col-sm-1-12 ds-m-r-md">
          <TimeInput
            autoComplete={autoCompletPrefix ? `${autoCompletPrefix}minute` : 'minute'}
            dateType="Minute"
            defaultValue={minute}
            name={name}
            label={label}
            onChange={handleChange}
            maxLength="2"
            fieldValidation={register({
              required: `Minute`,
            })}
          />
        </div>
        <div className="ds-col-1-5 ds-col-sm-1-9 ds-m-r-md">
          <Dropdown
            name={`${name}AmPm`}
            options={options}
            label="am or pm"
            autocomplete="time-ampm"
            defaultValue={defaultValue}
            fieldValidation={register({
              required: `Please select one option to proceed`,
            })}
          />
        </div>
        <input
          name={name}
          id={name}
          ref={fieldValidation}
          value={time || ''}
          type="hidden"
          autoComplete="name"
        />
      </div>
    </>
  );
};

Time.propTypes = {
  autoCompletPrefix: PropTypes.string,
  fieldValidation: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
};

Time.defaultProps = {
  autoCompletPrefix: '',
  fieldValidation: null,
};

export default Time;
