import React, { useRef } from 'react';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import Radios from 'components/shared/FormElements/Radios/Radios';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import InsetText from 'components/shared/InsetText/InsetText';

const OffenderPhoto = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  const radioButtons = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
  ];

  // Add InsetText with extra info when selected option is "no"
  let extraInfo;
  const selectedOption = document.querySelector(
    'input.ds-fe-radios__input[name="OffenderPhoto"]:checked'
  );
  if (selectedOption && selectedOption.value === 'no') {
    extraInfo = (
      <InsetText
        classes="ds-m-b-lg"
        content="You can always sign up to email alerts later in the disruption alerts dashboard."
      />
    );
  }

  const title = 'Do you have a photo of the offender?';

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 2 of 2" description="About the incident" />

      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{title}</h2>
        </legend>

        <Radios
          name="OffenderImages"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
        />

        {extraInfo}
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default OffenderPhoto;
