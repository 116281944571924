import React, { useRef } from 'react';

// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';

// Import components
import Address from 'components/Form/Shared/Address/Address';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';
import { useFormContext } from 'react-hook-form';

const IncidentLocation = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  const { register, unregister } = useFormContext({
    shouldUnregister: true,
    shouldUseNativeValidation: true,
  }); // Get useForm methods

  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 2 of 2" description="About the incident" />

      {/* Show generic error message */}
      {showGenericError}

      {/* Show incident address */}
      <Address register={register} unregister={unregister} />

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default IncidentLocation;
