import React, { useRef, useContext } from 'react';
// Import custom hooks

// Import components
import Dropdown from 'components/shared/FormElements/Dropdown/Dropdown';
import { useFormContext } from 'react-hook-form';
import { FormDataContext } from 'globalState/FormDataContext';

const IncidentTyleList = () => {
  const { register } = useFormContext(); // Get useForm methods
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext

  const incidentType = formDataState.formData.IncidentType;

  const fieldValidation = register({
    required: 'Enter a Date of the incident',
  });

  const options = [
    { label: 'Choose from list', value: '' },
    { label: 'Alcohol related / drunken behaviour', value: 'Alcohol related / drunken behaviour' },
    { label: 'Begging / rough sleeping', value: 'Begging / rough sleeping' },
    { label: 'Bus Shelters', value: 'Bus Shelters' },
    { label: 'Crime', value: 'Crime' },
    { label: 'Cycling / Scooters', value: 'Cycling / Scooters' },
    { label: 'Drug use', value: 'Drug use' },
    { label: 'Graffiti', value: 'Graffiti' },
    { label: 'Littering', value: 'Littering' },
    {
      label: 'Missile throwing / throwing stones / objects',
      value: 'Missile throwing / throwing stones / objects',
    },
    { label: 'Missing Person(s)', value: 'Missing Person(s)' },
    { label: 'Noise', value: 'Noise' },
    { label: 'Out of Area', value: 'Out of Area' },
    { label: 'Overcrowding', value: 'Overcrowding' },
    {
      label: 'Rowdy behaviour / disruptive passengers / Disruption of service',
      value: 'Rowdy behaviour / disruptive passengers / Disruption of service',
    },
    { label: 'Sexual Offences', value: 'Sexual Offences' },
    { label: 'Smoking / vaping', value: 'Smoking / vaping' },
    {
      label: 'Threats / abuse / intimidation towards passengers',
      value: 'Threats / abuse / intimidation towards passengers',
    },
    {
      label: 'Threats / abuse / intimidation towards staff',
      value: 'Threats / abuse / intimidation towards staff',
    },
    { label: 'Trespass on rail lines', value: 'Trespass on rail lines' },
    { label: 'Vandalism', value: 'Vandalism' },
    { label: 'Walkers / fare evasion', value: 'Walkers / fare evasion' },
  ];

  return (
    <fieldset className="ds-fe-fieldset ds-col-1">
      <legend className="ds-fe-fieldset__legend">
        <h2 className="ds-fe-question">What type of incident happened?</h2>
      </legend>

      <Dropdown
        name="IncidentType"
        options={options}
        label="Incident type*"
        autocomplete="incident-type"
        defaultValue={incidentType}
        fieldValidation={register({
          required: `Please select one option to proceed`,
        })}
      />
    </fieldset>
  );
};

export default IncidentTyleList;
