const d = new Date().toISOString().slice(0, 10); // Set todays date as yyyy-mm-dd

const dateRegex =
  /^(0[1-9]|[12][0-9]|3[01])[-/](0[13578]|10|12)[-/](19[0-9][0-9]|2[0-9][0-9][0-9])|(0[1-9]|[12][0-9]|30)[-/](0[469]|11)[-/](19[0-9][0-9]|2[0-9][0-9][0-9])|(0[1-9]|1[0-9]|2[0-8])[-/]02[-/](19[0-9][0-9]|2[0-9][0-9][0-9])|29[-/]02[-/](([02468][048])|([13579][26])00)|29[-/]02[-/]([0-9][0-9](0[48]|[2468][048]|[13579][26]))$/;

const timeRegex = /^(1[0-2]|0?[1-9]):([0-5]?[0-9])?$/;

const dateRegexSimple =
  /^(?:(?:19\d\d|[2-9]\d\d\d)(?:(?:(?:0[1-9]|1[0-2])(?:0[1-9]|1\d|2[0-8]))|(?:(?:(?:0[13578]|1[02])31)|(?:(?:0[1,3-9]|1[0-2])(?:29|30)))))|(?:(?:(?:19|[2-9]\d)(?:0[48]|[2468][048]|[13579][26])|(?:(?:[2468][048]|[3579][26])00))0229)$/;

const months = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

// determine if number is between two values
const between = (number, min, max) => number >= min && number <= max;

// return date as an array [yyyy, mm, dd]
const dateSplit = (date) => date.split('-');

// make sure day entry is valid
const validateDay = (value) => between(dateSplit(value)[2], 1, 31);

// make sure month entry is between 1 & 12
const validateMonth = (value) => between(dateSplit(value)[1], 1, 12);

// check if date is in the past (years ago)
const pastDate = (value, yearsAgo = 0) => {
  return d > value && dateSplit(value)[0] <= dateSplit(d)[0] - yearsAgo;
};

const currentDate = new Date();

const dateInPastArrow = (firstDate, secondDate = currentDate) => {
  const dateFormat = firstDate.split('-').reverse().join('-');
  const currentDateFormat = new Date(secondDate);

  const date = new Date(dateFormat);
  const current = new Date(currentDateFormat);

  return date.getTime() < current.getTime();
};

const dateInPastArrow2 = (firstDate, secondDate = currentDate) => {
  const dateFormat = firstDate;
  const currentDateFormat = new Date(secondDate);

  const date = new Date(dateFormat);
  const current = new Date(currentDateFormat);

  return date.getTime() < current.getTime();
};

// check if date is in the future (years)
const futureDate = (value, yearsAgo = 0) =>
  d < value && dateSplit(value)[0] >= dateSplit(d)[0] - yearsAgo;

const getDaysFromNow = (fromNow) => {
  const dateFromNow = new Date(); // Now
  const newDate = () => dateFromNow.toISOString().slice(0, 10);
  dateFromNow.setDate(dateFromNow.getDate() + fromNow);
  return newDate();
};

const daysFromNow = (value, fromNow) => {
  const newDate = getDaysFromNow(fromNow);
  return fromNow < 0 ? value >= newDate : value <= newDate;
};

const getDateFormatted = (val, includeYear = true) => {
  const date = val.split('-');

  return includeYear ? `${date[2]} ${months[date[1]]} ${date[0]}` : `${date[2]} ${months[date[1]]}`;
};

const dateValidationHelpers = {
  dateRegex,
  timeRegex,
  dateRegexSimple,
  validateDay,
  validateMonth,
  getDateFormatted,
  daysFromNow,
  getDaysFromNow,
  pastDate,
  dateInPastArrow,
  dateInPastArrow2,
  futureDate,
  months,
};

export default dateValidationHelpers;
